import {
    ApolloClient,
    ApolloLink,
    from as compileLink,
    InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import lodash from 'lodash';
import userManager from '../Authentication/userManager';
import honeycombLink from '../honeycomb/honeycombLink';
import errorLink from './middleware/errorLink';
import setRequestID from './middleware/setRequestID';
import setProductVariantCode from './middleware/setProductVariantCode';
import possibleTypes from '../../../possibleTypes.json';

const graphqlUrl = window.config.CREATE_GRAPHQL_URL_FROM_HOSTNAME ?
    `${window.location.protocol}//${window.location.host}/svc/graphql-gateway/graphql` :
    window.config.PUBLIC_GRAPHQL_URL;

const httpLink = new BatchHttpLink({
    uri: graphqlUrl,
    credentials: 'omit',
});

const OIDCAuthHeadersLink = setContext(() => (
    userManager.getUser().then((user) => {
        if (user?.id_token) {
            return {
                headers: {
                    authorization: `Bearer ${user.id_token}`,
                },
            };
        }
        return {};
    })
));

const links = [
    OIDCAuthHeadersLink,
    new ApolloLink(setRequestID),
    new ApolloLink(setProductVariantCode),
    new ApolloLink(honeycombLink),
    errorLink(),
    httpLink,
];

const link = compileLink(links);

function showLess(existing, args) {
    const pageSize = args?.args?.params?.pageSize || 10;
    let updatedData;
    if (existing?.pageInfo?.hasNext === false) {
        const remove = existing.data.length % pageSize;
        if (remove === 0) {
            updatedData = lodash.slice(
                existing.data,
                0,
                existing.data.length - pageSize,
            );
        } else {
            updatedData = lodash.slice(
                existing.data,
                0,
                existing.data.length - remove,
            );
        }
    } else {
        updatedData = lodash.slice(
            existing.data,
            0,
            existing.data.length - pageSize,
        );
    }

    const afterCursor = updatedData[updatedData.length - 1].term;
    let beforeCursor = updatedData[updatedData.length - pageSize].term;
    let hasPrevious = true;
    if (beforeCursor === updatedData[0].term) {
        beforeCursor = null;
        hasPrevious = false;
    }
    const pageInfo = {
        afterCursor,
        beforeCursor,
        hasNext: true,
        hasPrevious,
    };

    return {
        pageInfo,
        data: updatedData,
    };
}
const client = new ApolloClient({
    cache: new InMemoryCache({
        possibleTypes,
        typePolicies: {
            Query: {
                fields: {
                    glossaryTerms: {
                        keyArgs: ['params', ['tags', 'firstLetter', 'ietfTag', 'pageSize']],
                        merge(existing, incoming, args) {
                            if (!existing) {
                                return incoming;
                            }
                            if (args?.args?.params?.pageBefore) {
                                return showLess(existing, args);
                            }
                            return {
                                ...existing,
                                ...incoming,
                                data: lodash.unionBy(existing.data, incoming.data, 'termVariantId'),
                            };
                        },
                    },
                },
            },
            Cloudinary: {
                keyFields: ['publicId'],
            },
        },
    }),
    link,
    name: 'twigscience-middle-school',
    uri: graphqlUrl,
    version: window.config.IMAGE_GIT_COMMIT_SHA,
});

export default client;
