import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import AccessErrorPage from '../AccessErrorPage';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';

const TeacherKnowledge = withProductSubscriptions((props) => (
    <AsyncComponent
        appName="ms-teacher-knowledge-mfe"
        componentName="TeacherKnowledge"
        {...props}
    />
));

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];
const routes = [
    <Route
        path="/teacher-background-knowledge/*"
        component={() => (
            <WithAuthentication
                roles={allowedRoles}
                RoleErrorComponent={() => <AccessErrorPage link="/student-dashboard/" />}
            >
                <TeacherKnowledge Layout={Layout} />
            </WithAuthentication>
        )}
        key="teacher-background-knowledge"
    />,
];

export default routes;
