import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AsyncComponent } from '@twigeducation/async-component';
import { useQuery } from '@apollo/client';
import currentUserQuery from '../../CurrentUserQuery.query';

const Breadcrumb = ({ baseSessionPath, hideForRoles }) => {
    const subscriptions = useSelector((state) => state.subscriptions);
    const { tocsApp } = subscriptions.product;
    const { loading, data } = useQuery(currentUserQuery);
    const supportedLanguages = useSelector((state) => state.i18n.supportedLanguages);

    if (loading || hideForRoles?.includes(data?.currentUser?.role)) {
        return null;
    }

    return (
        <AsyncComponent
            appName="ms-breadcrumb-nav-mfe"
            componentName="Breadcrumb"
            applicationId={tocsApp}
            baseSessionPath={baseSessionPath}
            supportedLanguages={supportedLanguages.map((x) => x.code)}
        />
    );
};

Breadcrumb.defaultProps = {
    baseSessionPath: '/teacher-session',
    hideForRoles: null,
};

Breadcrumb.propTypes = {
    baseSessionPath: PropTypes.string,
    hideForRoles: PropTypes.arrayOf(PropTypes.string),
};

export default Breadcrumb;
