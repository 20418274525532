import React from 'react';
import Interchange from '@twigeducation/react-interchange';
import { useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';

const ResponsiveBreadcrumb = () => {
    const match = useRouteMatch('/lesson-explorer/int');
    return (
        <Interchange>
            <div data-at="breadcrumbs-small" media="small">
                { match?.isExact && <Breadcrumb /> }
            </div>
            <div data-at="breadcrumbs-medium" media="medium">
                <Breadcrumb />
            </div>
            <div data-at="breadcrumbs-large" media="large">
                <Breadcrumb />
            </div>
        </Interchange>
    );
};

export default ResponsiveBreadcrumb;
