import tracing from '../../honeycomb/tracing';

export const setHeader = ({
    headers = {},
}) => {
    const traceID = tracing.getTraceID();
    return {
        headers: {
            ...headers,
            'x-request-id': traceID,
        },
    };
};

export default (operation, forward) => {
    operation.setContext(setHeader);
    return forward(operation);
};
