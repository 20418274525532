import ReactGA from 'react-ga';

const setGAProperties = (user) => {
    ReactGA.ga('set', 'dimension1', user.user_id);
    ReactGA.ga('set', 'dimension2', user.role);
    ReactGA.ga('set', 'dimension3', user.district_id);
    ReactGA.ga('set', 'dimension4', user.is_trial_district ? 'Yes' : 'No');
    ReactGA.ga('set', 'dimension6', user.school_id || 'No school associated');
    ReactGA.ga('set', 'userId', user.user_id);
};

const clearGAProperties = () => {
    setGAProperties({ user_id: null, role: null });
};

export { clearGAProperties, setGAProperties };
