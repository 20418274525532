import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';

const StudentDashboard = withProductSubscriptions((props) => (
    <AsyncComponent
        appName="ms-student-dashboard-mfe"
        componentName="StudentDashboard"
        {...props}
    />
));

const allowedRoles = ['STUDENT', 'TWIGADMIN'];
const routes = [
    <Route
        path="/student-dashboard/*"
        component={() => (
            <WithAuthentication roles={allowedRoles}>
                <StudentDashboard Layout={Layout} />
            </WithAuthentication>
        )}
        key="student-dashboard"
    />,
];

export default routes;
