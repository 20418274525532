import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { initLibraryTranslations } from '@twigeducation/ts-fe-components';
import americanEnglish from '../i18n/English-US/translation.json';

if (NODE_ENV !== '') {
    const { I18N_EDITOR_ENABLED, I18N_BACKEND_ENABLED, I18N_BACKEND_LOAD_PATH } = window.config;
    const i18nextConfig = {
        lng: 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
        load: 'currentOnly',
        debug: NODE_ENV !== 'production',
    };

    i18next.use(initReactI18next);

    if (I18N_EDITOR_ENABLED) {
        import('../../.phrase.yml').then((phraseConfig) => {
            import('i18next-phrase-in-context-editor-post-processor').then((i18nEditor) => {
                if (i18nEditor) {
                    const PhraseInContextEditorPostProcessor = i18nEditor.default;
                    i18next.use(
                        new PhraseInContextEditorPostProcessor({
                            phraseEnabled: I18N_EDITOR_ENABLED,
                            projectId: phraseConfig.default?.phrase?.project_id,
                            debugMode: NODE_ENV !== 'production',
                        }),
                    );
                }
            });
        });
    }

    if (I18N_BACKEND_ENABLED && I18N_BACKEND_LOAD_PATH) {
        i18next.use(HttpApi);
        i18nextConfig.preload = ['en-US', 'es-US'];
        i18nextConfig.backend = {
            loadPath: I18N_BACKEND_LOAD_PATH,
            crossDomain: true,
        };

        if (I18N_EDITOR_ENABLED) {
            i18nextConfig.postProcess = ['phraseInContextEditor'];
        }
    } else {
        i18nextConfig.resources = {
            'en-US': {
                translation: americanEnglish,
            },
        };
    }

    i18next.init(i18nextConfig);
}

initLibraryTranslations();
