import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA4 from 'react-ga4';
import useTranslation from '../../hooks/useTranslation';
import { updatePrimaryLanguage } from '../../store/actions/i18n';
import getUserPreferencesByUserIdQuery from './userPreferences.query';

const I18n = ({ children }) => {
    const { i18n, ready } = useTranslation();
    const { userId, role } = useSelector((state) => state?.userInfo);
    const supportedLanguages = useSelector((state) => state?.i18n?.supportedLanguages?.map((lng) => lng?.code));
    const internationalisationEnabled = useSelector((state) => state?.featureFlags?.['ms-internationalisation']);
    const dispatch = useDispatch();

    const {
        data, loading, error,
    } = useQuery(getUserPreferencesByUserIdQuery, {
        skip: !internationalisationEnabled || !userId || role !== 'STUDENT',
        variables: {
            userId,
        },
    });

    useEffect(() => {
        if (!internationalisationEnabled || !ready || loading || error || role !== 'STUDENT') {
            return;
        }

        const currentLanguage = i18n?.language;
        const language = data?.getUserPreferencesByUserId?.settings?.primary_language;

        if (language && language !== currentLanguage && supportedLanguages.includes(language)) {
            i18n.changeLanguage(language);
            document.documentElement.setAttribute('lang', language);
            dispatch(updatePrimaryLanguage(language));
            ReactGA4.set({ i18n_primaryLanguage: language });
        }
    }, [internationalisationEnabled, role, data, loading, error, ready]);

    return (<>{children}</>);
};

I18n.propTypes = {
    children: PropTypes.node.isRequired,
};

export default I18n;
