import { SET_AVAILABLE_LANGUAGES, UPDATE_PRIMARY_LANGUAGE } from '../../actions/i18n/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
    case SET_AVAILABLE_LANGUAGES:
        return {
            ...state,
            supportedLanguages: action.value,
        };
    case UPDATE_PRIMARY_LANGUAGE:
        return {
            ...state,
            primaryLanguage: action.value,
        };
    default:
        return state;
    }
};
