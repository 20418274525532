import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';
import { connect } from 'react-redux';

const Navbar = (props) => (
    <AsyncComponent
        appName="ms-navbar-mfe"
        componentName="Navbar"
        {...props}
    />
);

const mapStateToProps = (state) => ({ userInfo: state.userInfo, hostApp: 'MS' });
export default withSignoutRedirect(() => {})(connect(mapStateToProps)(Navbar));
