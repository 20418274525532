import { useEffect } from 'react';
import { createNotification } from '@twigeducation/ts-fe-components';
import useTranslation from '../../hooks/useTranslation';

export const displayRSErrorNotification = (translationFn) => {
    createNotification({
        type: 'negative',
        duration: 10000,
        title: translationFn('errors.readspeaker_toast.title', 'Sorry, something went wrong!'),
        description: translationFn(
            'errors.readspeaker_toast.description',
            `An error occurred when trying to render the ReadSpeaker component.
            Please try and refresh the page.`,
        ),
    });
};

const ReadSpeakerBoundary = () => {
    const { t } = useTranslation();

    useEffect(() => () => {
        const readspeakerContainers = document.querySelectorAll('[class*=ReadSpeakerContainer]');

        if (readspeakerContainers.length > 0) {
            displayRSErrorNotification(t);
        }
    }, []);

    return null;
};

export default ReadSpeakerBoundary;
