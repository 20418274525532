import {
    actions,
} from '../../actions/subscriptions';

const { SET_PRODUCT } = actions;

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_PRODUCT:
        return {
            ...state,
            product: action.value,
        };
    default:
        return state;
    }
};
