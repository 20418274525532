import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';

const Footer = (props) => (
    <AsyncComponent
        appName="ms-footer-mfe"
        componentName="Footer"
        unleash={{
            clientId: window.config.UNLEASH_CLIENT_ID,
            url: window.config.UNLEASH_URL,
        }}
        {...props}
    />
);

export default Footer;
