import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import AccessErrorPage from '../AccessErrorPage';
import Layout from '../../layout';
import ResponsiveBreadcrumb from '../../components/ResponsiveBreadcrumb';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import useIsLtiUser from '../../components/Subscriptions/useIsLtiUser';

const LessonExplorer = (props) => (
    <AsyncComponent
        appName="ms-lesson-explorer-mfe"
        componentName="LessonExplorer"
        unleash={{
            clientId: window.config.UNLEASH_CLIENT_ID,
            url: window.config.UNLEASH_URL,
        }}
        {...props}
    />
);

const mapStateToProps = (state) => ({ userId: state.userInfo?.id });
const EnhancedLessonExplorer = connect(mapStateToProps)(LessonExplorer);

const LessonExplorerPage = withProductSubscriptions(() => {
    const isLtiUser = useIsLtiUser();

    return (
        <>
            {!isLtiUser && <ResponsiveBreadcrumb />}
            <EnhancedLessonExplorer Layout={Layout} />
        </>
    );
});

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];
const routes = [
    <Route
        path="/lesson-explorer"
        component={() => (
            <WithAuthentication
                roles={allowedRoles}
                RoleErrorComponent={() => <AccessErrorPage link="/student-dashboard" />}
            >
                <LessonExplorerPage />
            </WithAuthentication>
        )}
        key="lesson-explorer"
    />,
];

export default routes;
