import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeadAttributes } from '@twigeducation/allsorts';
import useTranslation from '../../hooks/useTranslation';
import tracing from '../../honeycomb/tracing';
import { StyledPageError } from './NotFound.styled';

const NotFound = () => {
    useEffect(() => {
        tracing.sendSpan('404', {}, 0, Date.now(), false);
    }, []);

    const history = useHistory();
    const { t } = useTranslation();
    return (
        <>
            <PageHeadAttributes
                title={t('not_found_page.page_title', 'Not Found')}
                separator="pipe"
            />
            <StyledPageError
                title={t('not_found_page.title', "Oops! We can't find what you're looking for!")}
                body={t('not_found_page.message', "Sorry, this page doesn't exist.")}
                buttonConfig={{
                    label: t('not_found_page.button_label', 'Go to my Dashboard'),
                    onClick: () => history.push('/'),
                }}
            />
        </>
    );
};

export default NotFound;
