import React, { useEffect, useState } from 'react';
import { FeatureFlag } from '@twigeducation/unleash-client-react';
import { useSelector } from 'react-redux';
import useUpsertUserPreferencesByUserId from '../../../shared/hooks/mutations/useUpsertUserPreferenceByUserId';
import css from './DevTools.module.css';

const DevTools = () => {
    const [controlsOpen, setControlsOpen] = useState(false);
    const [language, setLanguage] = useState(null);
    const [upsertUserPreferencesByUserId] = useUpsertUserPreferencesByUserId();
    const userId = useSelector((state) => state?.userInfo?.userId);

    useEffect(() => {
        if (language !== null) {
            upsertUserPreferencesByUserId({
                variables: {
                    input: {
                        userId,
                        settings: {
                            primary_language: language,
                        },
                    },
                },
            });
        }
    }, [language]);

    return (
        <FeatureFlag
            key="ms-dev-menu"
            flagKey="ms-dev-menu"
            renderFeatureCallback={() => (
                <div className={css.wrapper}>
                    <div
                        className={css.header}
                        onClick={() => setControlsOpen(!controlsOpen)}
                        onKeyDown={() => setControlsOpen(!controlsOpen)}
                        role="button"
                        tabIndex={0}
                    >
                        <span>Dev Controls &or;</span>
                    </div>
                    <div className={[css.listItems, `${controlsOpen && css.listItemsOpen}`].join(' ')}>
                        <span>
                            Set Language:
                            <button
                                type="button"
                                onClick={() => setLanguage('en-US')}
                                onKeyDown={() => setLanguage('en-US')}
                                className={css.languageBtn}
                            >
                                en-US
                            </button>
                            {' '}
                            |
                            <button
                                type="button"
                                onClick={() => setLanguage('es-US')}
                                onKeyDown={() => setLanguage('es-US')}
                                className={css.languageBtn}
                            >
                                es-US
                            </button>
                        </span>
                    </div>

                </div>
            )}
            renderDefaultCallback={() => null}
        />
    );
};

export default DevTools;
