import { actions } from '../../actions/featureFlags';

const initialState = {};

const featureFlagsReducer = (state = initialState, action) => {
    let newState = {};
    switch (action.type) {
    case actions.SET_FEATURE_FLAGS:
        newState = action.payload;
        break;
    default:
        newState = { ...state };
    }
    return newState;
};

export default featureFlagsReducer;
