/* eslint-disable max-len */
import styled from 'styled-components';

const LayoutRouteWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    height: 100%;
    padding: 0;
`;

const LayoutRouteContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    @media print{
        display: block;
    }
`;

const LayoutRouteFooter = styled.div`
    flex-shrink: 0;
`;

export {
    LayoutRouteWrapper,
    LayoutRouteContent,
    LayoutRouteFooter,
};
