import React from 'react';
import { useParams } from 'react-router-dom';
import ReadSpeaker from '../../components/ReadSpeaker';
import AssetGlossaryPage from './AssetGlossaryPage';
import AssetPage from './AssetPage';

const AssetPageRedirect = (props) => {
    const { type } = useParams();
    if (type === 'glossary-term') return <AssetGlossaryPage {...props} ReadSpeaker={ReadSpeaker} />;
    return <AssetPage {...props} />;
};

export default AssetPageRedirect;
