import React from 'react';
import PropTypes from 'prop-types';
import { Interchange } from '@twigeducation/react-interchange';
import Translation from '../Translation';
import { StyledSkipToContent } from './SkipToContent.styled';

const visibleViewports = ['medium', 'large'];

const SkipToContent = ({ target }) => (
    <Interchange>
        {
            visibleViewports.map((viewport) => (
                <StyledSkipToContent secondary href={target} media={viewport} key={viewport}>
                    <Translation defaultValue="Skip to content" translationKey="general.skip_to_content" />
                </StyledSkipToContent>
            ))
        }
    </Interchange>
);

SkipToContent.propTypes = {
    target: PropTypes.string.isRequired,
};

export default SkipToContent;
