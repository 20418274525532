import { onError } from '@apollo/client/link/error';
import tracing from '../../honeycomb/tracing';

const errorLink = () => onError(({ networkError, operation }) => {
    if (networkError) {
        const { operationName } = operation;
        tracing.sendSpan(
            'graphql-query',
            {
                operation_name: operationName,
                graphql_network_errors: networkError,
            },
            0,
            Date.now(),
        );
    }
});

export default errorLink;
