import React from 'react';
import PropTypes from 'prop-types';
import { Translation as I18NextTranslation } from 'react-i18next';
import config from '../../../../package.json';

const Translation = ({
    translationKey, defaultValue, options, ...props
}) => (
    <I18NextTranslation {...props}>
        {(t) => t(`${config.name}.${translationKey}`, defaultValue, options)}
    </I18NextTranslation>
);

Translation.defaultProps = {
    options: {},
};

Translation.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    options: PropTypes.shape(),
    translationKey: PropTypes.string.isRequired,
};

export default Translation;
