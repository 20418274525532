import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import store from './store/store';

// Set the history basename if accessing a review app
const pathname = window.location.pathname.split('/');
const isReviewApp = pathname.includes('rvw') && pathname.length >= 4;
const basename = pathname.slice(0, pathname.indexOf('rvw') + 2).join('/');
const history = isReviewApp ? createBrowserHistory({ basename }) : createBrowserHistory();

if (typeof window.config.GOOGLE_ANALYTICS === 'string' &&
window.config.GOOGLE_ANALYTICS !== 'false' &&
window.config.GOOGLE_ANALYTICS !== '') {
    history.listen((location) => {
        const state = store.getState();
        const { userInfo } = state;

        ReactGA.set(
            {
                page: location.pathname,
                userId: userInfo.id,
                dimension1: userInfo.id,
                dimension2: userInfo.role,
                dimension3: userInfo.districtId,
                dimension4: userInfo.isTrialDistrict ? 'Yes' : 'No',
                dimension6: userInfo.schoolId,
            },
        );
    });
}

export { basename };
export default history;
