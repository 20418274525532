import { Log, UserManager, WebStorageStateStore } from '@twigeducation/oidc-client-react';

const userManager = new UserManager({
    authority: `${window.config.OIDC_PROVIDER_URL}/`,
    automaticSilentRenew: true,
    client_id: window.config.SSO_CLIENT_ID,
    post_logout_redirect_uri: window.config.SSO_LOGOUT_CALLBACK_URL,
    monitorSession: window.config.SSO_MONITOR_SESSION,
    redirect_uri: window.config.SSO_LOGIN_CALLBACK_URL,
    response_type: 'code',
    scope: 'openid read write',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const configureOIDCLogging = (environment) => {
    Log.logger = console;
    if (environment.toLowerCase() === 'production') {
        Log.level = Log.ERROR;
    } else {
        Log.level = Log.DEBUG;
    }
};
configureOIDCLogging(NODE_ENV.toLowerCase());

export default userManager;
