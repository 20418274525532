import React from 'react';
import { Route } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import { NotFound } from '@twigeducation/allsorts';
import { FeatureFlag } from '@twigeducation/unleash-client-react';

const Favourites = ((props) => (
    <FeatureFlag
        key="ms-favourites"
        flagKey="ms-favourites"
        renderFeatureCallback={() => (
            <AsyncComponent
                appName="ms-favourites-mfe"
                componentName="Favourites"
                {...props}
            />
        )}
        renderDefaultCallback={() => <NotFound />}
    />
));

const routes = [
    <Route
        path="/favorites"
        key="favorites"
        component={() => (
            <WithAuthentication>
                <Favourites />
            </WithAuthentication>
        )}
    />,
];

export default routes;
