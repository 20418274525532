import React from 'react';
import PropTypes from 'prop-types';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';
import { useAnalyticsEvent } from '@twigeducation/analytics';

const LogoutButton = ({ onLogout }) => {
    const analyticsEventForLogout = useAnalyticsEvent({
        category: 'Hello World',
        action: 'Log Out',
        label: 'Log Out - Button Clicked',
    });

    const handleOnClick = analyticsEventForLogout(() => onLogout());

    return <button onClick={handleOnClick} type="button">Logout</button>;
};

LogoutButton.propTypes = {
    onLogout: PropTypes.func.isRequired,
};

export default withSignoutRedirect(() => { })(LogoutButton);
