import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { useSelector } from 'react-redux';
import withProductSubscriptions from '../../components/withProductSubscriptions';

const AssetGlossaryPage = withProductSubscriptions((props) => {
    const subscriptions = useSelector((state) => state.subscriptions);
    const { tocsApp } = subscriptions.product;
    const { versionId } = useParams();

    return (
        <AsyncComponent
            appName="ms-asset-preview-mfe"
            componentName="AssetGlossaryPage"
            versionId={versionId}
            applicationId={tocsApp}
            {...props}
        />
    );
});

AssetGlossaryPage.propTypes = {
    versionId: PropTypes.string.isRequired,
};

export default AssetGlossaryPage;
