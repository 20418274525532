import { useSelector } from 'react-redux';

const useIsLtiUser = () => {
    const subscriptionProductConfigs = useSelector(
        (state) => state.subscriptions?.product?.configuration,
    ) || [];
    const index = subscriptionProductConfigs.findIndex(
        (config) => config.key === 'integrationType' && config.value === 'LTI',
    );
    return index > -1;
};

export default useIsLtiUser;
