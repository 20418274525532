import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';

const ErrorBoundary = (message = '', errorLoggers = [], ErrorComponent) => {
    const ErrorBoundaryHOC = (Component) => {
        class ErrorBoundaryComponent extends React.Component {
            constructor(props) {
                super(props);
                this.state = { error: null, errorInfo: null };
            }

            componentDidCatch(error, errorInfo) {
                this.setState({
                    error,
                    errorInfo,
                });
                this.sendEvent();
                Sentry.withScope((scope) => {
                    scope.setTag('errorBoundary', 'true');
                    Sentry.captureException(error);
                });
                errorLoggers.forEach((logger) => {
                    logger(error, errorInfo);
                });
            }

            sendEvent() {
                ReactGA.event({
                    action: 'Error msg displayed',
                    category: 'Errors',
                    label: message || 'An unknown error occurred',
                });
            }

            render() {
                const { error, errorInfo } = this.state;

                if (errorInfo) {
                    return (
                        <>
                            {ErrorComponent && <ErrorComponent message={message} />}
                            {message && !ErrorComponent && (
                                <div>
                                    <h4>Sorry, something went wrong</h4>
                                    <p>{message}</p>
                                </div>
                            )}
                            {NODE_ENV === 'development' && (
                                <div>
                                    <h5>Error caught by ErrorBoundary</h5>
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        <div>{error && error.toString()}</div>
                                        <div>{errorInfo.componentStack}</div>
                                    </details>
                                </div>
                            )}
                        </>
                    );
                }
                return <Component {...this.props} />;
            }
        }

        ErrorBoundaryComponent.defaultProps = {
            children: '',
        };

        ErrorBoundaryComponent.propTypes = {
            children: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node, PropTypes.array]),
        };

        return ErrorBoundaryComponent;
    };

    return ErrorBoundaryHOC;
};

export default ErrorBoundary;
