import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import React from 'react';
import { Route } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';
import Readspeaker from '../../components/ReadSpeaker';

// eslint-disable-next-line react/display-name
const StudentSession = React.memo((props) => (
    <AsyncComponent
        appName="ms-student-session-mfe"
        componentName="StudentSession"
        cloudinaryUrl={window.config.CLOUDINARY_URL}
        docreaderCustomerId={window.config.DOCREADER_CUSTOMER_ID}
        docreaderVoiceEnglish={window.config.READSPEAKER_VOICE}
        docreaderVoiceSpanish={window.config.READSPEAKER_SPANISH_VOICE}
        unleash={{
            url: window.config.UNLEASH_URL,
            clientId: window.config.UNLEASH_CLIENT_ID,
        }}
        autosaveWSUrl={window.config.LESSON_ANSWER_SERVICE_WEBSOCKET_API}
        {...props}
    />
));

const ReactQuill = (props) => (
    <AsyncComponent
        appName="twig-quill-mfe"
        componentName="TwigQuill"
        {...props}
    />
);

const StudentSessionPage = withProductSubscriptions(() => (
    <>
        <Breadcrumb
            baseSessionPath="/student-session"
            hideForRoles={['STUDENT']}
        />
        <StudentSession
            Layout={Layout}
            reactQuill={ReactQuill}
            Readspeaker={Readspeaker}
        />
    </>
));

const allowedRoles = ['STUDENT', 'TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];
const routes = [
    <Route
        path="/student-session/*"
        key="student-session"
        component={() => (
            <WithAuthentication roles={allowedRoles}>
                <StudentSessionPage />
            </WithAuthentication>
        )}
    />,
];

export default routes;
