import React from 'react';
import { Route } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';

const CreditsPage = ((props) => (
    <AsyncComponent
        appName="ts-credits-mfe"
        componentName="CreditsPage"
        {...props}
    />
));

const routes = [
    <Route
        path="/credits"
        key="credits"
        component={() => (
            <WithAuthentication>
                <CreditsPage />
            </WithAuthentication>
        )}
    />,
];

export default routes;
